<template>
  <div class="table-container">
    <table
      class="results-table table table_default"
      :class="{'results-table_openable' : isOpenable}"
    >
      <colgroup>
        <col width="1">
        <col width="300">
      </colgroup>
      <thead class="table__header">
      <tr>
        <th class="text-center" style="min-width: 0;">{{ $t('result.position') }} ({{ positionByText.toLowerCase() }})</th>
        <th class="member-td">{{ $t('result.team') }}</th>
        <template v-if="!config.isComplex">
          <th
            class="text-center"
            v-if="!config.hideIndividualResult"
            :class="{
              'color-primary': config.highlightResultColumn
                && calcMethod === ResultCalcMethod.Individual,
            }"
            :style="config.highlightResultColumn
              && calcMethod === ResultCalcMethod.Individual
              && 'background-color: rgba(1, 1, 1, 0.04)'"
          >
            <span class="v-align-middle">{{ $t('result.result') }} (chip)</span>
            <v-popover placement="bottom-center" class="v-align-middle" trigger="hover" boundariesElement="body" style="line-height: 0;">
              <Icon class="color-muted" name="info-circle" size="s"></Icon>
              <template slot="popover">
                <div v-html="chipTooltip"></div>
              </template>
            </v-popover>
          </th>
          <th
            class="text-center"
            v-if="!config.hideAbsoluteResult"
            :class="{
              'color-primary': config.highlightResultColumn
                && calcMethod === ResultCalcMethod.Absolute,
            }"
            :style="config.highlightResultColumn
              && calcMethod === ResultCalcMethod.Absolute
              && 'background-color: rgba(1, 1, 1, 0.04)'"
          >
            <span class="v-align-middle">{{ $t('result.result') }} (gun)</span>
            <v-popover placement="bottom-center" class="v-align-middle" trigger="hover" boundariesElement="body" style="line-height: 0;">
              <Icon class="color-muted" name="info-circle" size="s"></Icon>
              <template slot="popover">
                <div v-html="gunTooltip"></div>
              </template>
            </v-popover>
          </th>
        </template>
        <template v-if="config.isComplex">
          <th class="text-center">{{ $t('result.result') }}</th>
        </template>
        <template v-for="stage in (stagesVisible || checkpointsVisible ? stages : [])">
          <th
            v-if="stagesVisible || !stage.stagesInfo.length"
            :key="stage.id"
            class="text-center"
            :class="`bg-table-${stage.disciplineCode}`"
          >
            {{ stage.name }}
          </th>
          <template v-else>
            <th
              v-for="checkpoint in stage.stagesInfo"
              :key="checkpoint.id"
              class="text-center"
              :class="`bg-table-${stage.disciplineCode}`"
            >
              <Icon v-if="stage.disciplineCode" :name="stage.disciplineCode" size="xs" class="color-muted"></Icon>&nbsp;{{ checkpoint.name }}
            </th>
          </template>
        </template>
        <th v-if="!hideCategory">{{ $t('result.category') }}</th>
      </tr>
      </thead>
      <tbody>
      <template
        v-for="(result, i) in results"
      >
        <!-- Row : BEGIN -->
        <tr
          :key="i"
          :data-id="result.teamId"
          :class="{
            'table__highlight': openedResult === result.teamId,
            'table__highlight-border': idHighlighted === result.teamId,
          }"
          @click="isOpenable ? openResult(result.teamId) : null"
        >
          <td class="text-center">
            <ResultPosition
              :position="result[positionByKey]"
              :status="result.status"
              :leadersCount="absoluteLeadersCount"
              :isRelay="true"
            ></ResultPosition>
          </td>
          <td class="member-td">
            <Member
              :key="openedResult === result.participantId ? +new Date() : +new Date() + 1"
              :name="result.teamName"
              :imgSize="48"
              :nameTo="getDetailsLink(result.teamId)"
              @clickImg.prevent.stop="!hideCompareButton ? $emit('compare', result.participantId) : null"
            >
              <template v-if="!hideCompareButton" v-slot:action>
                <CompareButton
                  :active="compareList.indexOf(result.teamId) !== -1"
                  @toggle="$emit('compare', result.teamId)"
                ></CompareButton>
              </template>
              <span @click.stop="">
                <Link
                  v-if="!config.hideVideo && config.hasVideo && result.video && result.video.length"
                  class="mt-8 mr-4"
                  variant="secondary"
                  icon-left="video"
                  size="lg"
                  :title="$t('result.video')"
                  @click="$emit('openVideoFinish', result.video)"
                ></Link>
                <Link
                  v-if="!config.hideDiploma && result.status === ResultStatus.Finish"
                  class="mt-8 mr-4"
                  variant="secondary"
                  icon-left="solution"
                  size="lg"
                  :title="$t('result.certificate')"
                  :href="getTeamDiplomaUrl(result.teamId)"
                  target="_blank"
                ></Link>
                <Link
                  v-if="!config.hidePhoto && config.photoUrlTemplate && result.status === ResultStatus.Finish"
                  class="mt-8 mr-4"
                  variant="secondary"
                  icon-left="camera"
                  size="lg"
                  :title="$t('result.photos')"
                  :href="getPhotoUrl()"
                  target="_blank"
                ></Link>
              </span>
            </Member>
          </td>
          <template v-if="!config.isComplex">
            <td
              class="text-center font-monospace"
              v-if="!config.hideIndividualResult"
              :style="config.highlightResultColumn
                && calcMethod === ResultCalcMethod.Individual
                && 'background-color: rgba(1, 1, 1, 0.04)'"
            >
              <TimeOffset
                v-if="result.timeOffset && result.timeOffset !== '00:00'"
                :offset="result.timeOffset"
                :result="result.individualResult"
              ></TimeOffset>
              <span class="text-lg v-align-middle">{{ result.individualTimeResultOffset || '--' }}</span>
              <div class="text-sm color-muted mt-4" v-if="result.individualDelay">
                +{{ result.individualDelay }}
              </div>
            </td>
            <td
              class="text-center font-monospace"
              v-if="!config.hideAbsoluteResult"
              :style="config.highlightResultColumn
                && calcMethod === ResultCalcMethod.Absolute
                && 'background-color: rgba(1, 1, 1, 0.04)'"
            >
              <TimeOffset
                v-if="result.timeOffset && result.timeOffset !== '00:00'"
                :offset="result.timeOffset"
                :result="result.absoluteResult"
              ></TimeOffset>
              <span class="text-lg v-align-middle">{{ result.absoluteTimeResultOffset || '--' }}</span>
              <div class="text-sm color-muted mt-4" v-if="result.absoluteDelay">
                +{{ result.absoluteDelay }}
              </div>
            </td>
          </template>
          <template v-if="config.isComplex">
            <td class="text-center font-monospace">
              <TimeOffset
                v-if="result.timeOffset && result.timeOffset !== '00:00'"
                :offset="result.timeOffset"
                :result="result.resultByCalcMethod"
              ></TimeOffset>
              <span class="text-lg v-align-middle">
                {{ result.timeResultByCalcMethod || '--' }}
              </span>
              <div class="text-sm color-muted mt-4" v-if="result.delayByCalcMethod">
                +{{ result.delayByCalcMethod }}
              </div>
            </td>
          </template>
          <template v-for="stage in (stagesVisible || checkpointsVisible ? result[stagesKey] : [])">
            <td
              v-if="stagesVisible || !stage[stagesKey].length"
              :key="stage.id"
              class="text-center font-monospace"
              :class="`bg-table-${stage.disciplineCode}`"
            >
              <TimeOffset
                v-if="stage.timeOffset && stage.timeOffset !== '00:00'"
                :offset="stage.timeOffset"
              ></TimeOffset>
              <span class="text-lg v-align-middle">
                {{ stage.resultByCalcMethod || '--' }}
              </span>
              <div
                class="text-sm color-muted mt-4"
                v-if="stage.delayByCalcMethod"
              >
                +{{ stage.delayByCalcMethod }}
              </div>
            </td>
            <template v-else>
              <td
                v-for="checkpoint in stage[stagesKey]"
                :key="checkpoint.id"
                class="text-center font-monospace"
                :class="`bg-table-${stage.disciplineCode}`"
              >
                <TimeOffset
                  v-if="checkpoint.timeOffset && checkpoint.timeOffset !== '00:00'"
                  :offset="checkpoint.timeOffset"
                ></TimeOffset>
                <span class="text-lg v-align-middle">
                  {{ checkpoint.resultByCalcMethod || '--' }}
                </span>
                <div
                  class="text-sm color-muted mt-4"
                  v-if="checkpoint.delayByCalcMethod"
                >
                  +{{ checkpoint.delayByCalcMethod }}
                </div>
              </td>
            </template>
          </template>
          <td v-if="!hideCategory">
            <div class="text-lg" style="max-width: 200px;">{{ result.genderAgeNominationName || '--' }}</div>
          </td>
        </tr>
        <!-- Row : END -->
        <!-- Details: BEGIN -->
        <tr
          v-if="isOpenable"
          :key="`${i}_details`"
          class="bg-light"
          v-show="openedResult === result.teamId"
        >
          <td colspan="100">
            <div class="block block_wide p-0">
              <div class="block__group">
                <Row>
                  <Col size="stretch">
                    <Member
                      v-if="openedResult === result.teamId"
                      :name="result.teamName"
                      :imgSize="56"
                      :imgTo="getDetailsLink(result.teamId)"
                      :nameTo="getDetailsLink(result.teamId)"
                    >
                      <template v-slot:name="">
                        <div class="text-lg"><b>{{ result.teamName }}</b></div>
                      </template>
                    </Member>
                  </Col>
                  <Col size="auto">
                    <Button
                      variant="secondary"
                      size="sm"
                      :text="$t('result.detail_page')"
                      :to="getDetailsLink(result.teamId)"
                    ></Button>
                  </Col>
                </Row>
              </div>
              <div
                class="block__group section-block__group_bordered-top"
                v-if="result[stagesKey] && result[stagesKey].length"
              >
                <table class="table table_default table_striped">
                  <thead class="table__header">
                  <tr>
                    <th>{{ $t('result.stage') }}</th>
                    <th class="text-center">{{ $t('result.result') }}</th>
                    <th class="text-center">
                      {{
                        config.isComplex
                          ? `${$t('result.pace')} / ${$t('result.speed')}`
                          : config.disciplineCode === 'cycling'
                          ? `${$t('result.speed')}`
                          : `${$t('result.pace')}`
                      }}
                    </th>
                    <th class="text-center">{{ $t('result.position') }} ({{ $t('result.position_cat') }})</th>
                    <th class="text-center">{{ $t('result.position') }} ({{ $t('result.position_abs') }})</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    v-for="stage in result[stagesKey]"
                    :key="stage.stageId"
                    :class="[
                      config.isComplex && stage.disciplineCode && `bg-table-${stage.disciplineCode}`,
                      stage.isMissed && 'color-muted'
                    ]"
                  >
                    <td>
                      <div class="stage-name">
                        <div
                          class="stage-name__icon"
                          :class="`bg-${stage.disciplineCode}`"
                        >
                          <Icon
                            v-if="stage.disciplineCode"
                            :name="stage.disciplineCode"
                            size="l"
                          ></Icon>
                          <span v-else>
                            {{ stage.name && stage.name[0] }}
                          </span>
                        </div>
                        <div class="stage-name__text">
                          <div class="text-lg">
                            <b>{{ stage.name }}</b>
                          </div>
                          <div class="text-sm mt-4" v-if="stage[stagesKey].length && stage.participant">
                            <Icon
                              class="icon"
                              :name="stage.participant.gender === Gender.Male ? 'male-color' : 'female-color'"
                              size="s"
                            />
                            <span class="v-align-middle" :title="stage.participant.fullName">
                              {{ getShortName(stage.participant.fullName) }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="text-center font-monospace">
                      <TimeOffset
                        v-if="stage.timeOffset && stage.timeOffset !== '00:00'"
                        :offset="stage.timeOffset"
                      ></TimeOffset>
                      <span class="text-lg v-align-middle">
                        {{ stage.resultByCalcMethod || '--' }}
                      </span>
                      <div
                        class="text-sm color-muted mt-4"
                        v-if="stage.delayByCalcMethod"
                      >
                        +{{ stage.delayByCalcMethod }}
                      </div>
                    </td>
                    <td class="text-center font-monospace">
                      <span
                        class="text-lg"
                        v-html="formatPaceText(stage.disciplineCode === 'cycling' || config.disciplineCode === 'cycling'
                          ? stage.speed
                          : stage.pace
                        ) || '--'"
                      ></span>
                    </td>
                    <td class="text-center font-monospace">
                      <span class="text-lg">{{ stage.genderAgeStagePosition || '--' }}</span>
                    </td>
                    <td class="text-center font-monospace">
                      <span class="text-lg">{{ stage.position || '--' }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </td>
        </tr>
        <!-- Details: END -->
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import resultsMixin from './resultsMixin';

export default {
  name: 'RelayResultsTable',
  mixins: [resultsMixin],
  props: {
    isOpenable: Boolean,
    showCheckpoints: Boolean,
    hideCompareButton: Boolean,
  },
  data() {
    return {
      stagesKey: 'stagesResults',
    };
  },
  computed: {
    stagesVisible() {
      return this.config.isComplex ? !this.showCheckpoints : this.showCheckpoints;
    },
    checkpointsVisible() {
      return this.config.isComplex && this.showCheckpoints;
    },
  },
  methods: {
    getDetailsLink(id) {
      const route = this.$router.resolve({
        name: 'Team',
        params: { participantId: id },
      });
      return route.href;
    },
  },
};
</script>
